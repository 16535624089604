import React from 'react'
import { Grid } from '@material-ui/core'
import pancake from '../assets/images/pancake.png'
import '../assets/css/PancakeStarter.scss'
import animateScrollTo from 'animated-scroll-to'

class Header extends React.Component {
  render() {
    return (
      <section id="header">
        <div className="inner">
          <footer class="footer"></footer>

          <Grid
            style={{ width: '75%', marginLeft: '13%', top: '20%' }}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <div class="plate">
              <div class="butter falling-element"></div>
              <div class="pancake falling-element"></div>
              <div class="pancake falling-element"></div>
              <div class="plate-bottom"></div>
              <div class="shadow"></div>
            </div>
            <div>
              <h1 style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                Brev
              </h1>
              <p
                style={{
                  paddingTop: '0px',
                  marginTop: '0px',
                  top: '10px',
                  fontStyle: 'italic',
                }}
              >
                Build a hosted, production server in seconds.
              </p>
            </div>
          </Grid>
          <div style={{ marginTop: '25px' }}>
            <h5>
              Write your API code in the Brev developer environment, and by
              default it'll be hosted and ready to scale.
            </h5>
          </div>

          <ul className="actions">
            <li>
              <a
                className="button scrolly"
                onClick={() => {
                  animateScrollTo(document.querySelector('#waitlist'))
                }}
              >
                Get Early Access!
              </a>
            </li>
          </ul>
        </div>
      </section>
    )
  }
}

export class CompressedHeader extends React.Component {
  render() {
    return (
      <section id="header" style={{ paddingBottom: '1em !important' }}>
        <div className="inner">
          <footer class="footer"></footer>

          <Grid
            style={{ width: '75%', marginLeft: '13%', top: '20%' }}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <div class="plate">
              <div class="butter falling-element"></div>
              <div class="pancake falling-element"></div>
              <div class="pancake falling-element"></div>
              <div class="plate-bottom"></div>
              <div class="shadow"></div>
            </div>
            <div>
              <h1 style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                Brev
              </h1>
              <p
                style={{
                  paddingTop: '0px',
                  marginTop: '0px',
                  top: '10px',
                  fontStyle: 'italic',
                }}
              >
                never start from scratch again!
              </p>
            </div>
          </Grid>
        </div>
      </section>
    )
  }
}

export default Header
